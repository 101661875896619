<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-row class="pa-4" no-gutters>
    <v-col class="mr-3">
      <div class="text-h6">Create a note for</div>
      <div class="text-body-1">{{ patient.fullname }}</div>
      <v-textarea
        v-model.trim="details"
        class="my-3"
        placeholder="Write a note"
        :disabled="loading"
        outlined
        hide-details
      />
      <v-btn
        color="primary"
        width="140"
        :disabled="details == ''"
        :loading="loading"
        depressed
        rounded
        @click="createNote"
      >
        Create
      </v-btn>
    </v-col>

    {{/* Notes list */}}
    <v-col>
      <template v-if="patient.notes.length != 0">
        <div class="text-h6 d-flex">
          Notes <v-spacer></v-spacer>
          <div class="mt-2">
            <template v-if="type == 'asc'">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="info" @click="type = 'desc'"
                    ><v-icon>mdi-sort-ascending</v-icon></v-btn
                  >
                </template>
                <span>Sort Asc</span>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="info" @click="type = 'asc'"
                    ><v-icon>mdi-sort-descending</v-icon></v-btn
                  >
                </template>
                <span>Sort Desc</span>
              </v-tooltip>
            </template>
          </div>
        </div>
      </template>
      <v-layout class="fill-height" column>
        <template v-if="patient.notes.length != 0">
          <v-slide-y-transition group>
            {{/* TODO: Separar esto en un componente */}}
            <v-alert
              v-for="(note, i) in notes"
              :key="i"
              class="mb-2 cursor-pointer"
              color="blue"
              @click="showDetails(i)"
              v-ripple
              text
              dark
              dense
            >
              <v-layout class="mb-2" justify-space-between>
                <div class="text-body-1 font-weight-bold">
                  <v-badge
                    color="error"
                    :content="note.comments.length"
                    :value="note.comments.length > 0"
                    offset-x="18"
                    offset-y="10"
                  >
                    <v-icon color="primary" class="mr-1">
                      mdi-comment-text-multiple
                    </v-icon>
                  </v-badge>
                  {{
                    note.createdBy ? note.createdBy.fullname : $t("desconocido")
                  }}
                </div>
                <div class="text-caption">
                  {{ dateTime(note.createAt) }}
                </div>
              </v-layout>
              <v-layout class="mb-2" justify-space-between>
                <div class="text-body-2">
                  {{ note.details }}
                </div>
                <div class="text-caption text-capitalize">
                  {{ note.type && note.type == "ANY" ? "Note" : note.type }}
                </div>
              </v-layout>
            </v-alert>
          </v-slide-y-transition>
        </template>

        <div
          v-else
          class="grey--text lighten-1 font-weight-light text-h5 ma-auto"
        >
          This patient don't have notes
        </div>
      </v-layout>
    </v-col>

    <note-details
      v-model="dialogDetails"
      :details="patient.notes[currentNote]"
      @comment-created="$emit('update-patient')"
    />
  </v-row>
</template>

<script>
import { getAPI } from "@/api/axios-base";
import { notificationError, notifyInfo } from "@/components/Notification";
import moment from "moment";
import Vue from "vue";

const NoteDetails = () =>
  import(/* webpackChunkName: "note-details" */ "./NoteDetails.vue");

export default Vue.extend({
  components: { NoteDetails },
  name: "patient-notes",
  props: {
    patient: Object,
  },
  data() {
    return {
      type: "desc",
      loading: false,
      details: "",
      dialogDetails: false,
      currentNote: null,
    };
  },
  computed: {
    notes() {
      if (this.patient == undefined || this.patient == null) {
        return [];
      }
      if (this.patient.notes == null || this.patient.notes == undefined) {
        return [];
      }
      if (this.patient.notes.length == 0) {
        return [];
      }
      if (this.type == "desc") {
        const sort = this.patient.notes.sort(
          (a, b) => moment(b.createAt).unix() - moment(a.createAt).unix()
        );

        return sort;
      }

      const sort = this.patient.notes.sort(
        (a, b) => moment(a.createAt).unix() - moment(b.createAt).unix()
      );

      return sort;
    },
  },
  methods: {
    createNote() {
      const uuid = this.$route.params.uuid;

      this.loading = true;
      getAPI
        .post("/patient/createNote", {
          details: this.details,
          patientUuid: uuid,
        })
        .then(() => {
          notifyInfo("The note have been created");
          this.$emit("update-patient");
          this.loading = false;
          this.details = "";
        })
        .catch(() => {
          this.loading = false;
          notificationError();
        });
    },
    dateTime(dateISOString) {
      const date = new Date(dateISOString);

      return date.toLocaleString();
    },
    showDetails(noteIndex) {
      this.currentNote = noteIndex;
      this.dialogDetails = true;
    },
  },
});
</script>
<style lang="scss" scoped>
.typeorder {
  max-width: 100px !important;
}
.fill-height {
  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #1e1e24;
  }

  &::-webkit-scrollbar-thumb {
    background: #3dcbf7;
  }
}
</style>
<i18n>
{
  "en": {
    "desconocido": "Unknown"
   
  },
  "es": {
    "desconocido": "Desconocido"
    
  }
}
</i18n>
